import { useTranslations } from 'next-intl'
import type { ChangeEventHandler } from 'react'
import React from 'react'

import type { MustHaveProps } from '@/hocs/with-form'
import { c } from '@/utils/etc'

import type { SharedFieldInputProps } from './field-input/field-input'

interface RadioYesNoProps extends SharedFieldInputProps, MustHaveProps {
  label?: string
  value?: boolean
}

const RadioYesNo: React.FC<RadioYesNoProps> = (props) => {
  const t = useTranslations()
  const { name, label, containerClassName, value, error, onChange } = props

  const handleChange = React.useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange?.(event.target.value === 'true')
    },
    [onChange]
  )

  return (
    <div className={containerClassName}>
      {label && (
        <label className={c('form-label', error && 'text-danger-400')}>
          {label}
        </label>
      )}
      <div className="flex items-center gap-x-4">
        <div className="flex items-center">
          <input
            id={`${name}.yes`}
            name={name}
            type="radio"
            value="true"
            checked={value === true}
            onChange={handleChange}
          />
          <label htmlFor={`${name}.yes`} className="pl-2">
            {t('common.yes')}
          </label>
        </div>
        <div className="flex items-center">
          <input
            id={`${name}.no`}
            name={name}
            type="radio"
            value="false"
            checked={value === false}
            onChange={handleChange}
          />
          <label htmlFor={`${name}.no`} className="pl-2">
            {t('common.no')}
          </label>
        </div>
      </div>
    </div>
  )
}

export default RadioYesNo
