import { useRouter } from 'next/router'
import * as React from 'react'
import { useField, useForm } from 'react-final-form'

import type { BrasilAPICepResponse } from '@/utils/types'
import { statesOptions } from '@/utils/types'

import type { CepInputProps } from './cep-input'

const abortControllers: AbortController[] = []

export const useCepInput = ({
  name,
  accessors,
  disabled,
}: Pick<CepInputProps, 'name' | 'accessors' | 'disabled'>) => {
  const { batch, change } = useForm()
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } })
  const [fetchingCep, setFecthingCep] = React.useState(false)
  const [firstAccess, setFirstAccess] = React.useState(true)

  const { query } = useRouter()

  React.useEffect(() => {
    if (!accessors || disabled) return

    // Ajustar esse trava - Solução para correção de um bug critico
    if (firstAccess && query.edit === 'true') {
      setFirstAccess(false)
      return
    }
    const cepNumbersOnly = value?.replace(/[^\d]/g, '')
    if (!cepNumbersOnly || cepNumbersOnly.length !== 8 || fetchingCep) return

    const baseUrl = 'https://brasilapi.com.br/api/cep/v2'
    const controller = new AbortController()
    abortControllers.push(controller)

    setFecthingCep(true)

    fetch(`${baseUrl}/${cepNumbersOnly}`, { signal: controller.signal })
      .then((r) => r.json())
      .then((r: BrasilAPICepResponse) => {
        const state = statesOptions.find(({ value }) => value === r.state)

        batch(() => {
          if (r.street) {
            change(accessors.rua, r.street.split('-')[0])
          }

          if (r.neighborhood) {
            change(accessors.bairro, r.neighborhood)
          }

          if (r.city) {
            change(accessors.cidade, r.city)
          }

          if (state) {
            change(accessors.estado, state)
          }
        })
      })
      .catch(() => undefined)
      .finally(() => setFecthingCep(false))

    // A gente só se importa com o estado de value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, disabled])

  React.useEffect(() => {
    return () => {
      abortControllers.forEach((controller) => {
        controller.abort()
      })
    }
  }, [])

  return {}
}
