import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-final-form'

import type { FileRecord, Media } from '@/utils/types/common'

import type { AlbumInputProps } from './album-input'

export const useAlbumInput = ({
  max = 5,
  name,
  value = [],
}: Pick<AlbumInputProps, 'max' | 'name' | 'value'>) => {
  const { change, blur } = useForm()

  const medias = React.useMemo(
    () => (value || []) as (Media | FileRecord)[],
    [value]
  )

  const onDropAccepted = React.useCallback(
    (files: File[]) => {
      const arr = files
        .filter(
          (file) =>
            !medias.find((m) => {
              const name = 'file' in m ? m.file.name : m.name
              return name === file.name
            })
        )
        .map((file) => ({
          file: file,
          thumbUrl: URL.createObjectURL(file),
        }))

      if (arr.length + medias.length > max) {
        return
      }

      change(name, medias.concat(arr))
    },
    [medias, max, name, change]
  )

  const blurIt = React.useCallback(() => blur(name), [name, blur])

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: max,
    multiple: true,
    maxSize: 5 * 1024 * 1024,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'video/mp4': ['.mp4'],
      'video/x-msvideo': ['.x-msvideo'],
    },
    onDropAccepted,
    onFileDialogCancel: blurIt,
    onDropRejected: blurIt,
  })

  const gridTemplateColumns = React.useMemo(
    () => `repeat(${max}, minmax(0, 1fr))`,
    [max]
  )

  const dropzoneGridColumn = React.useMemo(
    () => `span ${max - medias.length} / span ${max - medias.length}`,
    [max, medias]
  )

  const remove = React.useCallback(
    (m: Media | FileRecord) => {
      const mUrl = 'file' in m ? m.thumbUrl : m.url

      change(
        name,
        medias.filter((mm) => {
          const mmUrl = 'file' in mm ? mm.thumbUrl : mm.url
          return mUrl !== mmUrl
        })
      )

      // if ('thumbUrl' in m) {
      //   change(
      //     name,
      //     medias.filter((mm) => {
      //       // m.thumbUrl !== mm.thumbUrl
      //     })
      //   )
      // }
      // if ('url' in m) {
      //   change(
      //     name,
      //     medias.filter((mm: any) => m.url !== mm.url)
      //   )
      // }
    },
    [medias, name, change]
  )

  return {
    medias,
    gridTemplateColumns,
    dropzoneGridColumn,
    getRootProps,
    getInputProps,
    remove,
  }
}
