import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import prettyBytes from 'pretty-bytes'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { Field, useForm } from 'react-final-form'
import { HiOutlineDownload, HiOutlineTrash } from 'react-icons/hi'
import { RiLoader4Line } from 'react-icons/ri'
import { TbFileX } from 'react-icons/tb'

import Button, { Loader } from '@/components/core/Button'
import { useReadonly } from '@/contexts/readonly.context'
import { useAuth } from '@/hooks/use-auth'
import { ext } from '@/utils/core/etc'
import { c } from '@/utils/etc'
import * as fetch from '@/utils/fetch'
import type { TipoArquivo } from '@/utils/types'
import type { FileRecord } from '@/utils/types/common'

import TextInfo from '../../TextInfo'
import type { SharedFieldInputProps } from '../field-input/field-input'
import {
  makeAcceptFromMimes,
  mapAcceptToReadableString,
} from '../MultipleFile/MultipleFile.hook'

const PDFImagePreview = dynamic(() => import('../MultipleFile/PDFImagePreview'))

export interface InputFileProps extends SharedFieldInputProps {
  name: string
  type?: TipoArquivo
  label?: string
  labelTooltip?: string
  accept?: string[]
  maxSizePerFile?: number
  upload?: boolean
  canDelete?: boolean
  defaultValue?: FileRecord | null
  onChange?: ({ files, name }: { files: FileRecord[]; name: string }) => void
  onDelete?: (name: string) => void
}

const FileInput: React.FCC<InputFileProps> = ({
  name,
  type = 'arquivo',
  className,
  upload = true,
  maxSizePerFile = 5000000,
  containerClassName,
  inputWrapperClassName,
  canDelete = true,
  accept = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  label,
  labelTooltip,
  defaultValue,
  passthrough,
  onChange,
  onDelete,
}) => {
  const { change, blur } = useForm()
  const { readonly } = useReadonly()

  const {
    user: { perfil },
    context,
  } = useAuth()

  const enviadoPorId = perfil?.id || ''
  const enviadoPorPerfil = context || ''

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      blur(name)
      if (acceptedFiles.length < 1) return

      if (upload) {
        const fd = new FormData()
        fd.set('types', type)

        fd.set('files', acceptedFiles[0])
        fd.set('enviadoPorId', enviadoPorId)
        fd.set('enviadoPorPerfil', enviadoPorPerfil)

        const url = '/documents'

        change(name, { name: acceptedFiles[0].name })

        fetch
          .storage<FileRecord | FileRecord[]>(url, {
            method: 'POST',
            body: fd,
          })
          .then(async ({ data }) => {
            const arr = Array.isArray(data) ? data : [data]
            change(name, arr[0])

            onChange && (await onChange({ files: arr, name }))
          })
          .catch(() => {
            change(name, undefined)
          })
      } else {
        change(name, acceptedFiles[0])
      }
    },
    [blur, name, upload, type, enviadoPorId, enviadoPorPerfil, change, onChange]
  )

  const del = React.useCallback(() => {
    change(name, undefined)
    onDelete && onDelete(name)
  }, [change, name, onDelete])

  const {
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    disabled: readonly,
    multiple: false,
    accept: makeAcceptFromMimes(accept),
    maxSize: 5 * 1048576,
  })

  const id = React.useMemo(() => `${name}-f`, [name])
  const t = useTranslations('common')
  return (
    <Field
      name={name}
      subscription={{ value: true, touched: true, error: true }}
      defaultValue={defaultValue}
    >
      {({ input, meta }) => (
        <div className={containerClassName}>
          {label && (
            <TextInfo
              as="label"
              info={labelTooltip || ''}
              className="form-label"
              htmlFor={id}
            >
              {label}
            </TextInfo>
          )}

          <>
            {input.value && (
              <div className="flex items-center justify-between rounded-lg border border-primary-300 bg-primary-100 p-3">
                <span className="flex items-center space-x-2">
                  {input.value.key &&
                  ['.pdf', '.jpg', '.jpeg', '.png'].includes(
                    ext(input.value.key)
                  ) ? (
                    <div className="w-10 h-10 overflow-hidden flex items-center justify-center select-none relative rounded-md">
                      <div className="absolute top-0 right-0 left-0 bottom-0 bg-primary-300 bg-opacity-10 z-[1]" />

                      <PDFImagePreview
                        src={
                          input.value.url &&
                          `/api/test-file-previewer?url=${encodeURIComponent(
                            input.value.url
                          )}`
                        }
                      />
                    </div>
                  ) : (
                    <div className="w-10 h-10 overflow-hidden flex items-center justify-center select-none relative rounded-md">
                      {!input.value.url && upload ? (
                        <>
                          <RiLoader4Line
                            size={24}
                            className="animate-spin-speed text-primary-300 "
                          />
                          <div className="absolute top-0 right-0 left-0 bottom-0 bg-primary-300 bg-opacity-10 z-[1]" />
                        </>
                      ) : (
                        <>
                          <TbFileX className="w-6 h-6 text-primary-300" />
                          <div className="absolute top-0 right-0 left-0 bottom-0 bg-primary-300 bg-opacity-10 z-[1]" />
                        </>
                      )}
                    </div>
                  )}

                  <p className="font-medium text-dark-gray-500 flex-1">
                    {(input.value as FileRecord).name}
                  </p>
                </span>
                <span className="flex items-center space-x-4">
                  {input?.value?.url && (
                    <Link href={input.value.url} download target="_blank">
                      <HiOutlineDownload className="h-5 w-5" />
                    </Link>
                  )}

                  {upload && !input?.value?.url && (
                    <Loader className="relative h-5 w-5 text-primary-300" />
                  )}

                  {!readonly && (!upload || input?.value?.url) && canDelete && (
                    <Button
                      onClick={() => (input?.value ? del() : undefined)}
                      className="p-0 text-primary-300"
                    >
                      <HiOutlineTrash className="h-5 w-5" />
                    </Button>
                  )}
                </span>
              </div>
            )}

            {!input.value && (
              <div
                className={c(
                  meta.touched && meta.error
                    ? 'border-danger-300'
                    : 'border-primary-300',
                  'rounded-md border-2 border-dashed px-6 pt-5 pb-6 text-center cursor-pointer',
                  inputWrapperClassName
                )}
                {...getRootProps()}
              >
                <input
                  {...passthrough}
                  {...getInputProps()}
                  style={undefined}
                  hidden
                  name={name}
                  id={id}
                  className={className}
                />
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="currentColor"
                  className={c(
                    'mx-auto',
                    meta.touched && meta.error
                      ? 'text-danger-300'
                      : 'text-primary-300'
                  )}
                >
                  <path
                    d="M15 8H15.01"
                    // stroke="#3085E8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 4H7C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4Z"
                    // stroke="#3085E8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 15L8 11C8.45606 10.5612 8.97339 10.3301 9.5 10.3301C10.0266 10.3301 10.5439 10.5612 11 11L16 16"
                    // stroke="#3085E8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 14L15 13C15.4561 12.5612 15.9734 12.3301 16.5 12.3301C17.0266 12.3301 17.5439 12.5612 18 13L20 15"
                    // stroke="#3085E8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                {!isDragActive ? (
                  <p className="text-body-md font-medium text-light-gray-500">
                    {t('DRAGINDROP-TITLE')}&nbsp;{' '}
                    <span
                      className={c(
                        meta.touched && meta.error
                          ? 'text-danger-300'
                          : 'text-primary-300'
                      )}
                    >
                      {t('DRAGINDROP-SECOND-TEXT')}
                    </span>
                  </p>
                ) : (
                  ''
                )}
                {isDragActive && isDragAccept ? (
                  <p className="text-body-md font-medium text-success-300">
                    {t('DRAGINDROP-VALID-FILE')}
                  </p>
                ) : (
                  ''
                )}
                {isDragActive && isDragReject ? (
                  <p className="text-body-md font-medium text-danger-300">
                    {t('DRAGINDROP-INVALID-FILE')}
                  </p>
                ) : (
                  ''
                )}
                <p className="mb-4 text-body-md font-medium text-light-gray-400">
                  {t('DRAGINDROP-JUST-FILE')}{' '}
                  {mapAcceptToReadableString(accept)}.{' '}
                  {t('DRAGINDROP-MAX-SIZE-FILE')} {prettyBytes(maxSizePerFile)}
                </p>
              </div>
            )}

            {meta.touched && meta.error && !input.value?.name && (
              <p className="form-error">{meta.error}</p>
            )}
          </>
        </div>
      )}
    </Field>
  )
}

export default FileInput
