import * as React from 'react'
import { shallow } from 'zustand/shallow'

export const useSkipFirstRender = (
  cb: () => void,
  deps: any[] = [],
  skip = true
) => {
  const firstRender = React.useRef(skip)
  const depsRef = React.useRef(deps)

  React.useEffect(
    () => {
      let unmount

      if (!shallow(depsRef.current, deps) || !skip) {
        if (firstRender.current) {
          return () => {
            firstRender.current = false
          }
        }
        unmount = cb()
      }

      depsRef.current = deps

      return unmount
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )
}
