import * as React from 'react'

export interface WrapProps<P> {
  wrap?: boolean
  wrapper: React.FC<P & { children: React.ReactNode }>
  children?: React.ReactNode
}

const Wrap = <P,>({
  wrapper,
  wrap,
  children,
  ...props
}: WrapProps<P> & P): React.ReactElement => {
  const Wrapper = React.useMemo(() => wrapper, [wrapper])

  if (wrap) {
    return <Wrapper {...(props as unknown as P)}>{children}</Wrapper>
  }

  return <>{children}</>
}

export default Wrap
