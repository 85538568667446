import * as React from 'react'

import type { MustHaveProps } from '@/hocs/with-form'

import FieldInput from '../field-input'
import { useDocumentoInput } from './DocumentoInput.hook'

export interface DocumentoInputProps extends MustHaveProps {
  name: string
  error?: boolean
  accessors?: {
    nome_fantasia?: string
    razao_social?: string
    email?: string
    telefone?: string
    endereco_cep?: string
    endereco_numero?: string
  }
  onChange?: (v: unknown) => void
  onBlur?: () => void
  onFocus?: () => void
}

const DocumentoInput: React.FCC<DocumentoInputProps> = ({
  name,
  accessors,
  value,
  ...props
}) => {
  useDocumentoInput({ name, accessors })

  return (
    <FieldInput
      name={name}
      label="CPF/CNPJ"
      type="cpf_cnpj"
      value={value as never}
      {...props}
    />
  )
}

export default DocumentoInput
