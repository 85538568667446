import { useLocale } from 'next-intl'
import * as React from 'react'
import type { GroupBase, StylesConfig, ThemeConfig } from 'react-select'
import { defaultTheme } from 'react-select'

import type { Option } from '@/utils/types/common'

import twcss from '../../../../../tailwind.config'
import type { SelectInputProps } from './select-input'

export const useSelectInput = <T>({
  onChange,
  onFocus,
  onBlur,
  disabled,
  error,
  value,
  pill,
  placeholder,
  multiple,
  menuPlacement,
  isOptionDisabled,
}: SelectInputProps<T>) => {
  const lang = useLocale()

  const theme = React.useMemo<ThemeConfig>(
    () => ({
      ...defaultTheme,
      colors: {
        ...defaultTheme.colors,
        primary: twcss.theme.colors.primary[300],
        primary75: twcss.theme.colors.primary[200],
        primary50: twcss.theme.colors.primary[200],
        primary25: twcss.theme.colors.primary[100],
      },
    }),
    []
  )

  const styles = React.useMemo<
    StylesConfig<Option<T>, false, GroupBase<Option<T>>> | undefined
  >(
    () => ({
      control: (style, state) => ({
        ...style,
        ...(pill ? { paddingRight: 8 } : {}),
        ...(state.isDisabled
          ? {
              backgroundColor: 'white',
              borderColor: twcss.theme.colors['light-gray'][200],
              cursor: 'not-allowed',
            }
          : {
              boxShadow: state.menuIsOpen
                ? `0 0 0 1px ${twcss.theme.colors.primary[300]}`
                : error
                ? `0 0 0 1px ${twcss.theme.colors.danger[400]}`
                : state.isFocused
                ? `0 0 0 1px ${twcss.theme.colors.primary[300]}`
                : 'none',
              cursor: 'pointer',
              backgroundColor: 'white',
              borderColor: state.menuIsOpen
                ? twcss.theme.colors.primary[300]
                : error
                ? twcss.theme.colors.danger[400]
                : state.isFocused
                ? twcss.theme.colors.primary[300]
                : twcss.theme.colors['light-gray'][400],
              ':hover': {
                borderColor: state.menuIsOpen
                  ? twcss.theme.colors.primary[300]
                  : twcss.theme.colors['light-gray'][400],
              },
            }),
        borderRadius: pill ? 10000 : 6,
        borderWidth: 1,
        borderStyle: 'solid',
        minHeight: 42,
      }),

      indicatorSeparator: () => ({}),

      dropdownIndicator: (style, state) => ({
        ...style,
        color: twcss.theme.colors['light-gray'][state.isDisabled ? 300 : 400],
      }),

      placeholder: (style) => ({
        ...style,
        marginLeft: 2,
        color: twcss.theme.colors['light-gray'][400],
        fontWeight: 500,
        fontSize: 14,
      }),

      singleValue: (style) => ({
        ...style,
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: 2,
        color: twcss.theme.colors['dark-gray'][500],
      }),

      input: (style) => ({
        ...style,
        padding: '0px 0px',
        fontSize: 14,
      }),

      option: (style, state) => ({
        ...style,
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontWeight: 500,
        fontSize: 12,
        color: state.isSelected
          ? 'white'
          : twcss.theme.colors['dark-gray'][500],
        ...(state.isDisabled && {
          backgroundColor: 'white',
          color: twcss.theme.colors['light-gray'][400],
          cursor: 'not-allowed',
        }),
      }),

      menuPortal: (style) => ({
        ...style,
        pointerEvents: 'auto',
        zIndex: 9999,
      }),
    }),
    [pill, error]
  )

  const commonProps = React.useMemo(
    () => ({
      onChange,
      onFocus,
      onBlur,
      isDisabled: disabled,
      // For some reason react-select types for property isMulti is false | undefined
      isMulti: multiple as false | undefined,
      placeholder:
        placeholder || { en: 'Select one option...' }[lang] || 'Selecione...',
      value,
      theme,
      styles,
      menuPlacement,
      isOptionDisabled,
    }),
    [
      onChange,
      onFocus,
      onBlur,
      disabled,
      multiple,
      placeholder,
      lang,
      value,
      theme,
      styles,
      menuPlacement,
      isOptionDisabled,
    ]
  )

  return { commonProps }
}
