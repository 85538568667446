import { withForm } from '@/hocs/with-form'

import Album from './album-input'
import Cep from './cep-input'
import Checkbox from './checkbox-input'
import Cnpj from './cnpj-input'
import Date from './date-input'
import DocumentoInput from './DocumentoInput'
import Field from './field-input'
import File from './file-input'
import GoogleAddrInput from './GoogleAddrInput'
import MoneyInput from './moneyInput'
import MultipleFileInput from './MultipleFile'
import NotaFiscal from './NotaFiscal'
import Password from './password-input'
import Radio from './radio-input'
import RadioYesNo from './RadioYesNo'
import RangeInput from './range-input'
import Select from './select-input'
import Status from './status-input'
import Switch from './SwitchInput'
import TextArea from './TextArea'

export {
  Cep,
  Checkbox,
  Date,
  Field,
  File,
  MoneyInput,
  MultipleFileInput,
  NotaFiscal,
  Password,
  Radio,
  RadioYesNo,
  RangeInput,
  Select,
  Status,
  Switch,
  TextArea,
}

export const Form = {
  Field: withForm(Field) as typeof Field,
  Radio: withForm(Radio, { type: 'radio' }),
  Cep: withForm(Cep),
  Password: withForm(Password),
  Select: withForm(Select) as typeof Select,
  Checkbox: withForm(Checkbox, { type: 'checkbox' }),
  Cnpj: withForm(Cnpj),
  Album: withForm(Album),
  Status: withForm(Status),
  Date: withForm(Date),
  TextArea: withForm(TextArea),
  DocumentoInput: withForm(DocumentoInput),
  MoneyInput: withForm(MoneyInput),
  NotaFiscal: withForm(NotaFiscal),
  GoogleAddr: withForm(GoogleAddrInput),
  Switch: withForm(Switch, { type: 'checkbox' }),
  RadioYesNo: withForm(RadioYesNo),
  Range: withForm(RangeInput),
}
