import * as React from 'react'
import { HiOutlinePlus } from 'react-icons/hi'

import type { MustHaveProps } from '@/hocs/with-form'
import { c } from '@/utils/etc'
import type { Media } from '@/utils/types/common'

import { useAlbumInput } from './album-input.hook'
import MediaPreview from './media-preview'

export interface AlbumInputProps extends MustHaveProps {
  max?: number
  label?: string
}

const AlbumInput: React.FCC<AlbumInputProps> = ({
  max = 5,
  name,
  value,
  label,
  containerClassName,
  error,
}) => {
  const {
    gridTemplateColumns,
    dropzoneGridColumn,
    getRootProps,
    getInputProps,
    remove,
  } = useAlbumInput({ max, name, value })

  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={`${name}-f`}
          className="mb-2 block font-medium text-dark-gray-500"
        >
          {label}
        </label>
      )}
      <div style={{ gridTemplateColumns }} className="grid gap-4">
        {((value || []) as Media[]).map((media, i) => (
          <MediaPreview key={i} media={media} remove={remove} />
        ))}

        {((value || []) as Media[]).length < max && (
          <div
            {...getRootProps()}
            style={{ gridColumn: dropzoneGridColumn }}
            className={c(
              'flex min-h-[148px] cursor-pointer items-center justify-center rounded-lg border-2 border-dashed',
              error ? 'border-danger-300' : 'border-primary-300'
            )}
          >
            <HiOutlinePlus
              className={c(
                'h-6 w-6',
                error ? 'text-danger-300' : 'text-primary-300'
              )}
            />
            <input {...getInputProps()} hidden id={`${name}-f`} />
          </div>
        )}
      </div>
    </div>
  )
}

export default AlbumInput
