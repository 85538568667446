import type { MustHaveProps } from '@/hocs/with-form'
import { c } from '@/utils/etc'

export interface RangeInputProps extends MustHaveProps {
  name: string
  readOnly?: boolean
  checked?: boolean
  error?: boolean
  disabled?: boolean
  highlightWhenSelected?: boolean
  labelClassName?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  max: number
  min: number
  step: number
  format?: (value: number) => string
}

const RangeInput: React.FCC<RangeInputProps> = ({
  name,
  value,
  disabled,
  readOnly,
  containerClassName,
  className,
  passthrough,
  onChange,
  max,
  min,
  step,
  format = (value) => value.toString(),
}) => {
  return (
    <div className={`${containerClassName} w-full flex flex-col`}>
      <input
        type="range"
        max={max}
        min={min}
        step={step}
        readOnly={readOnly}
        name={name}
        value={value as never}
        onChange={onChange}
        disabled={disabled}
        {...passthrough}
        className={c(
          'mt-1.5',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          className
        )}
      />
      <div className="w-full flex justify-end">
        <h3 className="text-caption-md text-gray-600 flex gap-1 items-end">
          {format(+String(value) || 0)}
        </h3>
      </div>
    </div>
  )
}

export default RangeInput
