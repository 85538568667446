import * as React from 'react'

import type { MustHaveProps } from '@/hocs/with-form'

import FieldInput from '../field-input'
import { useCnpjInput } from './cnpj-input.hook'

export interface CnpjInputProps extends MustHaveProps {
  label?: string
  disabled?: boolean
  shouldFetch?: boolean
  accessors?: {
    nome_fantasia?: string
    razao_social?: string
    email?: string
    telefone?: string
    endereco_cep?: string
    endereco_numero?: string
  }
}

const CnpjInput: React.FCC<CnpjInputProps> = ({
  name,
  accessors,
  value,
  shouldFetch = true,
  label = 'CNPJ',
  ...props
}) => {
  useCnpjInput({ name, accessors, shouldFetch })
  return (
    <FieldInput
      name={name}
      label={label}
      type="cnpj"
      value={value as never}
      {...props}
    />
  )
}

export default CnpjInput
