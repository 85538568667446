import dynamic from 'next/dynamic'
import Image from 'next/legacy/image'
import { useTranslations } from 'next-intl'
import * as React from 'react'
import {
  HiOutlineDownload,
  HiOutlineExternalLink,
  HiOutlineTrash,
} from 'react-icons/hi'
import { TbLockAccess } from 'react-icons/tb'

import { useReadonly } from '@/contexts/readonly.context'
import { c } from '@/utils/etc'
import type { FileRecord, Media } from '@/utils/types/common'

const PDFImagePreview = dynamic(() =>
  import('../../MultipleFile/PDFImagePreview').then((mod) => mod.default)
)

export interface MediaPreviewProps {
  containerClassName?: string
  media: Media | FileRecord
  remove?: (m: Media | FileRecord) => void
}

const extMime: Record<string, string> = {
  mp4: 'video/mp4',
  avi: 'video/x-msvideo',
}

const MediaPreview: React.FCC<MediaPreviewProps> = ({
  media,
  containerClassName,
  remove,
}) => {
  const { readonly } = useReadonly()
  const t = useTranslations()

  const thumbnail = React.useMemo(() => {
    if (!media) {
      return null
    }
    const ext = ('file' in media ? media.file.name : media.key)
      .split('.')
      .slice(-1)[0]

    const url = 'thumbUrl' in media ? media.thumbUrl : media.url

    const videoType =
      'file' in media
        ? media.file.type
        : extMime[media.url.split('.').slice(-1)[0] || 'mp4']

    switch (ext) {
      case 'png':
      case 'jpg':
      case 'jpeg': {
        return (
          <Image key={url} src={url} layout="fill" objectFit="contain" alt="" />
        )
      }

      case 'pdf': {
        return (
          <PDFImagePreview
            src={`/api/test-file-previewer?url=${url}`}
            containerClassName="grid place-items-center px-2"
            renderFallback={({ error }) => (
              <p className="text-center text-body-sm text-primary-500 font-semibold">
                {(() => {
                  let msg: string

                  switch (error?.name) {
                    case 'PasswordException':
                      msg = t('common.media-preview.password')
                      break

                    default:
                      msg = t('common.media-preview.fail')
                      break
                  }

                  return msg
                })()}
              </p>
            )}
          />
        )
      }

      case 'mp4':
      case 'avi': {
        return (
          <video key={url} controls className="h-full w-full object-cover">
            <source src={url} type={videoType} />
          </video>
        )
      }
    }
  }, [media, t])

  const url = React.useMemo(
    () => (media ? ('url' in media ? media.url : media.thumbUrl) : ''),
    [media]
  )

  return (
    <div className={c('relative aspect-square', containerClassName)}>
      <div className="relative w-full h-full overflow-hidden rounded-lg border border-primary-300">
        <div className="absolute inset-0 bg-primary-100 bg-opacity-40" />

        {url === '' ? (
          <div className="flex flex-col gap-y-2 items-center justify-center h-full">
            <TbLockAccess
              className="text-primary-300 p-2 bg-light-gray-100 rounded-full"
              size={48}
            />
            <h4 className="text-body-sm font-medium">
              {t('common.media-preview.private')}
            </h4>
          </div>
        ) : (
          <span className="absolute inset-x-0 bottom-0 z-[1] flex justify-between bg-primary-200 p-2">
            <a href={url} download rel="noreferrer" target="_blank">
              <HiOutlineDownload className="h-5 w-5 text-primary-400" />
            </a>
            <a href={url} target="_blank" rel="noreferrer">
              <HiOutlineExternalLink className="h-5 w-5 text-primary-400" />
            </a>
          </span>
        )}

        {thumbnail}
      </div>

      {remove && !readonly && url !== '' && (
        <button
          type="button"
          className="absolute -right-4 -top-4 bg-white p-2 shadow-xl button"
        >
          <HiOutlineTrash
            onClick={() => remove && remove(media)}
            className="h-5 w-5 text-primary-300"
          />
        </button>
      )}
    </div>
  )
}

export default MediaPreview
