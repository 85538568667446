import * as React from 'react'
import { useField, useForm } from 'react-final-form'

import * as fetch from '@/utils/fetch'

import type { DocumentoInputProps } from './DocumentoInput'

export const useDocumentoInput = ({
  name,
  accessors,
}: Pick<DocumentoInputProps, 'name' | 'accessors'>) => {
  const { batch, change } = useForm()
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } })
  const [fetchingCnpj, setFecthingCnpj] = React.useState(false)

  const typeDocument = React.useCallback(() => {
    return value.length > 14 ? 'cnpj' : 'cpf'
  }, [value.length])

  React.useEffect(() => {
    if (!accessors) return

    const cnpjNumbersOnly = value?.replace(/[^\d]/g, '')
    if (!cnpjNumbersOnly || cnpjNumbersOnly.length !== 14 || fetchingCnpj)
      return

    setFecthingCnpj(true)

    fetch
      .portal(`/util/consulta_cnpj?cnpj=${cnpjNumbersOnly}`)
      .then((result: any) => {
        const estabelecimento = result.data.estabelecimento

        batch(() => {
          if (result.data.razao_social && accessors.razao_social) {
            change(accessors.razao_social, result.data.razao_social)
          }
          if (estabelecimento.nome_fantasia && accessors.nome_fantasia) {
            change(accessors.nome_fantasia, estabelecimento.nome_fantasia)
          }
          if (estabelecimento.cep && accessors.endereco_cep) {
            const split = estabelecimento.cep.split('')
            change(
              accessors.endereco_cep,
              `${split.slice(0, 5).join('')}-${split.slice(5).join('')}`
            )
          }
          if (estabelecimento.numero && accessors.endereco_numero) {
            change(accessors.endereco_numero, estabelecimento.numero)
          }
          if (estabelecimento.email && accessors.email) {
            change(accessors.email, estabelecimento.email.toLowerCase())
          }
          if (
            estabelecimento.ddd1 &&
            estabelecimento.telefone1 &&
            accessors.telefone
          ) {
            const split = estabelecimento.telefone1.split('')
            change(
              accessors.telefone,
              `(${estabelecimento.ddd1}) ${split.slice(0, 4).join('')}-${split
                .slice(4)
                .join('')}`
            )
          }
        })
      })
      .catch(() => undefined)
      .finally(() => setFecthingCnpj(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  return { typeDocument }
}
