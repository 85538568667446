import React from 'react'

import { env } from '@/utils/envs'
import { array } from '@/utils/etc'

import type { MoneyInputProps } from './MoneyInput'

export const useInputMoney = ({
  onValueChange,
  hasPrefix,
  name,
  children,
  onChange,
}: MoneyInputProps) => {
  const id = React.useMemo(() => `${name}-f`, [name])
  const _children = array(children).filter((v) => !!v)

  const before = _children.filter(
    ({ props: { slot } }: any) => slot === 'before'
  )
  const after = _children.filter(({ props: { slot } }: any) => slot === 'after')

  const locale = { en: 'en-US' }[env.LANGUAGE] || 'pt-BR'
  const prefix = hasPrefix ? { 'en-US': '$', 'pt-BR': 'R$' }[locale] : ''
  const currency = { 'en-US': 'USD', 'pt-BR': 'BRL' }[locale]
  const decimalScale = 2
  const decimalSeparator = { 'en-US': '.', 'pt-BR': ',' }[locale]
  const thousandSeparator = { 'en-US': ',', 'pt-BR': '.' }[locale]

  const handleChange = React.useCallback(
    (v: any, a: any) => {
      onChange && onChange(v.formattedValue)
      if (onValueChange) {
        onValueChange(
          { ...v, floatValue: v.floatValue / 10 ** decimalScale },
          a
        )
      }
    },
    [onValueChange, onChange, decimalScale]
  )

  const currencyFormatter = React.useCallback(
    (formatted_value: string) => {
      const value = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: decimalScale,
        maximumFractionDigits: decimalScale,
      }).format(
        !Number(formatted_value)
          ? 0
          : Number(formatted_value) / 10 ** decimalScale
      )

      return value
    },
    [locale, currency, decimalScale]
  )

  return {
    handleChange,
    currencyFormatter,
    locale,
    prefix,
    decimalScale,
    decimalSeparator,
    thousandSeparator,
    id,
    before,
    after,
  }
}
