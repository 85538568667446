import { useTranslations } from 'next-intl'
import prettyBytes from 'pretty-bytes'
import * as React from 'react'
import { Field } from 'react-final-form'
import { TbPhoto, TbPlus } from 'react-icons/tb'

import { useBreakpoint } from '@/hooks/core/useBreakpoint'
import { c } from '@/utils/etc'
import type { TipoArquivo } from '@/utils/types'
import type { FileRecord } from '@/utils/types/common'

import MediaPreview from '../album-input/media-preview/media-preview'
import type { SharedFieldInputProps } from '../field-input/field-input'
import { useMultipleFile } from './MultipleFile.hook'

export interface MultipleFileInputProps extends SharedFieldInputProps {
  name: string
  type?: TipoArquivo
  label?: string
  accept?: string[]
  upload?: boolean
  defaultValue?: FileRecord[] | null
  maxFiles?: number
  maxSizePerFile?: number
  canDelete?: boolean
  canInsert?: boolean
  onChange?: ({
    files,
    name,
  }: {
    files: FileRecord[]
    file: FileRecord
    name: string
  }) => void
  onDelete?: (p: { id: string }) => void
  slotCancelado?: boolean
}

const MultipleFileInput: React.FCC<MultipleFileInputProps> = ({
  name,
  type = 'arquivo',
  className,
  upload = true,
  maxFiles = 3,
  maxSizePerFile = 3000000,
  containerClassName,
  inputWrapperClassName,
  canDelete,
  canInsert = true,
  accept = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'video/mp4',
    'video/x-msvideo',
    'audio/mpeg',
    'audio/wav',
    'audio/ogg',
    'audio/mp3',
  ],
  label,
  defaultValue = [],
  onChange,
  onDelete,
  slotCancelado,
}) => {
  const {
    id,
    del,
    readonly,
    filesState,
    getRootProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    getInputProps,
    gridTemplateColumns,
    dropzoneGridColumn,
    mapAcceptToReadableString,
  } = useMultipleFile({
    maxFiles,
    name,
    type,
    upload,
    accept,
    maxSizePerFile,
    canInsert,
    onChange,
    onDelete,
  })

  const { xxs } = useBreakpoint()
  const t = useTranslations('common')

  if (filesState.length === 0 && slotCancelado === true) {
    return null
  }

  return (
    <Field
      name={name}
      subscription={{ value: true, touched: true, error: true }}
      defaultValue={defaultValue}
    >
      {({ meta }) => (
        <div className={containerClassName}>
          {label && (
            <label className="form-label" htmlFor={id}>
              {label}
            </label>
          )}

          <div
            {...(xxs && {
              style: { gridTemplateColumns },
            })}
            className="grid gap-4 max-xxs:block max-xxs:gap-0 max-xxs:h-[unset] max-xxs:space-y-4"
          >
            {filesState.map((file) => (
              <MediaPreview
                key={file.id}
                media={{
                  ...file,
                  // TODO refatorar componente de MultipleFile
                  thumbUrl: file.url || '',
                }}
                containerClassName="h-[148px] [aspect-ratio:unset!important]"
                {...(canDelete ? { remove: () => del(file) } : {})}
              />
            ))}

            {filesState.length < maxFiles && !readonly && (
              <div
                style={{ gridColumn: dropzoneGridColumn }}
                className={c(
                  meta.touched && meta.error
                    ? 'border-danger-300'
                    : 'border-primary-300',
                  'rounded-md border-2 flex-1 border-dashed flex items-center justify-center px-6 pt-5 pb-6 text-center h-[148px] cursor-pointer',
                  inputWrapperClassName
                )}
                {...getRootProps()}
              >
                <input
                  {...getInputProps()}
                  hidden
                  style={undefined}
                  name={name}
                  id={id}
                  className={className}
                />

                {filesState.length === 0 ? (
                  <span>
                    <TbPhoto
                      size={24}
                      className={c(
                        'mx-auto',
                        meta.touched && meta.error
                          ? 'text-danger-300'
                          : 'text-primary-300'
                      )}
                    />

                    {!isDragActive ? (
                      <p className="text-body-md font-medium text-light-gray-500">
                        {t('DRAGINDROP-TITLE')}&nbsp;{' '}
                        <span
                          className={c(
                            meta.touched && meta.error
                              ? 'text-danger-300'
                              : 'text-primary-300'
                          )}
                        >
                          {t('DRAGINDROP-SECOND-TEXT')}
                        </span>
                      </p>
                    ) : (
                      ''
                    )}
                    {isDragActive && isDragAccept ? (
                      <p className="text-body-md font-medium text-success-300">
                        {t('DRAGINDROP-VALID-FILE')}
                      </p>
                    ) : (
                      ''
                    )}
                    {isDragActive && isDragReject ? (
                      <p className="text-body-md font-medium text-danger-300">
                        {t('DRAGINDROP-INVALID-FILE')}
                      </p>
                    ) : (
                      ''
                    )}
                    <p className="mb-4 text-body-md font-medium text-light-gray-400">
                      {t('DRAGINDROP-JUST-FILE')}{' '}
                      {mapAcceptToReadableString(accept)}.{' '}
                      {t('DRAGINDROP-MAX-SIZE-FILE')}{' '}
                      {prettyBytes(maxSizePerFile)}
                    </p>
                  </span>
                ) : (
                  <span>
                    <TbPlus
                      size={24}
                      className={c(
                        'mx-auto',
                        meta.touched && meta.error
                          ? 'text-danger-300'
                          : 'text-primary-300'
                      )}
                    />
                  </span>
                )}
              </div>
            )}

            {!filesState.length && readonly && (
              <p className="col-start-1 col-end-7">
                {t('multiple-file.no-files')}
              </p>
            )}
          </div>
          {meta.touched && meta.error && filesState?.length !== 3 && (
            <p className="form-error">{meta.error}</p>
          )}
        </div>
      )}
    </Field>
  )
}

export default MultipleFileInput
