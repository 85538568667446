import React from 'react'
import type { UseFieldConfig } from 'react-final-form'
import { useField } from 'react-final-form'

import type { SharedFieldInputProps } from '@/components/core/input/field-input/field-input'
import { c } from '@/utils/etc'

export interface MustHaveProps extends SharedFieldInputProps {
  name: string
  value?: unknown
  error?: boolean
  defaultValue?: unknown
  onChange?: (v: any) => void
  onBlur?: () => void
  onFocus?: () => void
}

export const withForm = <T,>(
  Component: React.FCC<T & MustHaveProps>,
  options?: UseFieldConfig<unknown, unknown>
) => {
  const Render = ({
    radioValue,
    ...props
  }: Omit<
    T & MustHaveProps,
    'error' | 'value' | 'onChange' | 'onBlur' | 'onFocus'
  > & { radioValue?: string }) => {
    const { input, meta } = useField(props.name, {
      defaultValue: props.defaultValue,
      subscription: {
        value: true,
        touched: true,
        error: true,
        modified: true,
        submitFailed: true,
      },
      ...(radioValue ? { type: 'radio', value: radioValue } : {}),
      ...options,
    })

    return (
      <div className={c(props.containerClassName)}>
        {/* @ts-expect-error está errado, mas é improvável que dê ruim */}
        <Component
          {...props}
          {...input}
          error={
            meta.touched && (meta.modified || meta.submitFailed) && meta.error
          }
          containerClassName="w-full"
        />
        {meta.touched && (meta.modified || meta.submitFailed) && meta.error && (
          <p className="form-error">{meta.error.value || meta.error}</p>
        )}
      </div>
    )
  }

  Render.displayName = 'FormField'

  return React.memo(Render)
}
