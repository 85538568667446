import { useQuery } from '@tanstack/react-query'
import { useTranslations } from 'next-intl'
import React from 'react'
import { Form } from 'react-final-form'
import * as yup from 'yup'

import * as Input from '@/components/core/input'
import { money, strtonum, validateFormValues } from '@/utils/etc'
import * as fetch from '@/utils/fetch'

import Button from '../../Button'
import BaseModal from '../../Modals/BaseModal'
import type { BaseModalProps } from '../../Modals/BaseModal/BaseModal'

interface NotaFiscalProps extends BaseModalProps {
  label?: string
  file?: File
  onSubmit?: (v: NotaFiscalFormValues) => any
  tipoFixo?: string
  propostaId?: string
  milestoneId?: string
}

export interface NotaFiscalFormValues {
  valor: string
  dataEmissao: string
  chave: string
  file: File
}

const NotaFiscalModal: React.FCC<NotaFiscalProps> = ({
  label,
  file,
  open,
  onSubmit = () => undefined,
  onClose,
  tipoFixo,
  propostaId,
  milestoneId,
}) => {
  const [initialFile, setInitialFile] = React.useState(file)
  const t = useTranslations()

  const queryKeyValorMaximo = React.useMemo(() => {
    return milestoneId
      ? ['invoice-max-value', milestoneId]
      : ['nf', propostaId, 'valor-maximo-nf-documento']
  }, [propostaId, milestoneId])

  const { data: dataValorMaximoNf } = useQuery({
    queryKey: queryKeyValorMaximo,
    queryFn: () => {
      if (milestoneId) {
        return fetch
          .portal<number>(`/milestone/${milestoneId}/invoice-max-value`)
          .then(({ data }) => data)
          .catch(() => 0)
      } else {
        return fetch
          .formalizacao<number>(`/nf/proposta/${propostaId}/${tipoFixo}/max`)
          .then(({ data }) => data)
          .catch(() => 0)
      }
    },
    enabled: open,
  })

  React.useLayoutEffect(() => {
    if (!file) return
    setInitialFile(file)
  }, [file])

  const validate = React.useMemo(
    () =>
      validateFormValues(
        yup.object({
          valor: yup
            .string()
            .typeError(t('formalization.valor-invalido'))
            .test(
              'valorNotaFiscal',
              `${t('formalization.valor-maximo')} ${money(dataValorMaximoNf)}`,
              (val) => strtonum(val) <= (dataValorMaximoNf || 0)
            )
            .test(
              'valorNotaFiscal',
              t('formalization.valor-invalido'),
              (val) => strtonum(val) > 0
            )
            .required(t('formalization.campo-obrigatorio')),
          dataEmissao: yup
            .date()
            .required(t('formalization.campo-obrigatorio'))
            .typeError(t('formalization.data-invalida2')),
          chave: yup
            .string()
            .typeError(t('formalization.campo-invalido'))
            .required(t('formalization.campo-obrigatorio')),
          file: yup.mixed().required(t('formalization.campo-obrigatorio')),
        })
      ),
    [dataValorMaximoNf, t]
  )

  return (
    <BaseModal open={open} onClose={onClose}>
      <div className="mb-6">
        <h3 className="text-primary-500 text-title-xs font-bold">
          {t('common.nota-fiscal-modal.title')}
        </h3>
        {label && <h4 className="font-medium text-light-gray-500">{label}</h4>}
      </div>

      <Form
        onSubmit={onSubmit}
        initialValues={{ file: initialFile }}
        validate={validate}
        subscription={{}}
      >
        {({ handleSubmit }) => (
          <form className="space-y-6 mb-6">
            <Input.Form.MoneyInput
              name="valor"
              label={t('formalization.valor2')}
            />

            <Input.Form.Date
              name="dataEmissao"
              label={t('formalization.data-emissao2')}
            />

            <Input.Form.Field
              type={'text'}
              name="chave"
              label={t('formalization.chave-nota-fiscal')}
            />

            <Input.File name="file" label={label} upload={false} />

            <div className="flex items-center space-x-4 justify-end">
              <Button onClick={onClose} className="button--outline">
                {t('formalization.fechar')}
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                className="button--primary"
              >
                {t('formalization.salvar-documento')}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </BaseModal>
  )
}

export default NotaFiscalModal
