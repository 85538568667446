import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslations } from 'next-intl'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { TbPhoto, TbPlus } from 'react-icons/tb'

import type { MustHaveProps } from '@/hocs/with-form'
import { useAuth } from '@/hooks/use-auth'
import { c, defaultToastError, strtonum } from '@/utils/etc'
import * as fetch from '@/utils/fetch'
import type { FileRecord } from '@/utils/types/common'
import type { DocumentoFormulario } from '@/utils/types/structs/formulario'

import MediaPreview from '../album-input/media-preview'
import type { SharedFieldInputProps } from '../field-input/field-input'
import {
  makeAcceptFromMimes,
  mapAcceptToReadableString,
} from '../MultipleFile/MultipleFile.hook'
import type { NotaFiscalFormValues } from './NotaFiscalModal'
import NotaFiscalModal from './NotaFiscalModal'

interface NotaFiscalInputProps extends MustHaveProps, SharedFieldInputProps {
  label?: string
  accept?: string[]
  maxSizePerFile?: number
  maxFiles?: number
  slotId: string
  propostaId: string
  milestoneId?: string
  canDelete?: boolean
  canInsert?: boolean
  tipoFixo?: string
}

const NotaFiscalInput: React.FC<NotaFiscalInputProps> = ({
  name,
  accept = ['application/pdf'],
  label,
  error,
  value,
  maxSizePerFile = 5e6,
  maxFiles = 5,
  labelClassName,
  containerClassName,
  slotId,
  propostaId,
  milestoneId,
  canDelete = false,
  canInsert = false,
  onChange,
  tipoFixo,
}) => {
  const queryClient = useQueryClient()
  const id = React.useMemo(() => `${name}-f`, [name])

  const [file, setFile] = React.useState<File>()
  const { context, user } = useAuth()
  const t = useTranslations('common')

  const dropzoneStyle = React.useMemo<React.CSSProperties | undefined>(
    () =>
      Array.isArray(value)
        ? {
            gridColumn: `span ${maxFiles - value.length} / span ${
              maxFiles - value.length
            }`,
          }
        : { gridColumn: `span ${maxFiles} / span ${maxFiles}` },
    [value, maxFiles]
  )

  const containerStyle = React.useMemo<React.CSSProperties>(
    () => ({
      gridTemplateColumns: `repeat(${maxFiles}, minmax(0, ${maxFiles}fr))`,
    }),
    [maxFiles]
  )

  const clearFile = React.useCallback(() => setFile(undefined), [])

  const onDrop = React.useCallback((files: File[]) => {
    setFile(files[0])
  }, [])

  const del = useMutation({
    mutationFn: (fileRecord: FileRecord) => {
      if (!fileRecord.id) {
        throw new Error(
          'Não é possível excluir esse documento. Por favor, entre em contato com o suporte. (NOID)'
        )
      }
      return fetch.portal(`/proposta/${propostaId}/desassociar/documento`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ documentoId: fileRecord.id }),
      })
    },
    onSuccess: (_, fileRecord) => {
      onChange?.(
        Array.isArray(value)
          ? value.filter(({ _id }) => _id !== fileRecord._id)
          : []
      )

      if (propostaId) queryClient.invalidateQueries(['proposta', propostaId])
      if (milestoneId) queryClient.invalidateQueries(['milestones', propostaId])
    },
    onError: defaultToastError,
  })

  const onSubmit = useMutation({
    mutationFn: async (v: NotaFiscalFormValues) => {
      const fd = new FormData()

      fd.set('types', 'arquivo')
      fd.set('files', v.file)
      if (user?.credencial?.id) fd.set('enviadoPorId', user.credencial.id)
      if (context) fd.set('enviadoPorPerfil', context)

      const fileRecord = await fetch
        .storage<FileRecord[]>('/documents', {
          method: 'POST',
          body: fd,
        })
        .then(({ data }) => data?.[0])

      const docFormulario = await fetch
        .portal<DocumentoFormulario>(
          `/proposta/${propostaId}/associar/documento`,
          {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              slotId: slotId,
              documentoId: fileRecord._id,
              milestoneId,
              nf: {
                valor: strtonum(v.valor),
                dataEmissao: v.dataEmissao,
                chave: v.chave,
              },
            }),
          }
        )
        .then(({ data }) => data)

      return { ...fileRecord, id: docFormulario.id }
    },
    onSuccess: (fileRecord) => {
      setFile(undefined)
      onChange?.(Array.isArray(value) ? value.concat(fileRecord) : [fileRecord])

      if (propostaId) queryClient.invalidateQueries(['proposta', propostaId])
      if (milestoneId) queryClient.invalidateQueries(['milestones', propostaId])
    },
    onError: defaultToastError,
  })

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: makeAcceptFromMimes(accept),
    maxFiles,
    maxSize: maxSizePerFile,
    multiple: false,
    onDrop,
  })

  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={id}
          className={c(
            'mb-2 block font-medium text-dark-gray-500',
            labelClassName
          )}
        >
          {label}
        </label>
      )}

      <div style={containerStyle} className="grid gap-x-4">
        {Array.isArray(value) &&
          value.map((f: FileRecord) => (
            <MediaPreview
              key={f.key}
              containerClassName="h-[148px] [aspect-ratio:unset!important]"
              media={f}
              {...(canDelete ? { remove: () => del.mutateAsync(f) } : {})}
            />
          ))}

        {!canInsert && (!value || (Array.isArray(value) && !value.length)) && (
          <p className="col-start-1 col-end-7">{t('multiple-file.no-files')}</p>
        )}

        {(!Array.isArray(value) || value.length < maxFiles) && canInsert && (
          <div
            {...getRootProps()}
            style={dropzoneStyle}
            className={c(
              'border-2 border-dashed rounded-lg p-6 grid place-items-center cursor-pointer',
              error ? 'border-danger-300' : 'border-primary-300'
            )}
          >
            <input {...getInputProps()} id={id} />

            {Array.isArray(value) && value.length >= 3 && (
              <TbPlus
                className={c(
                  'w-6 h-6',
                  error ? 'text-danger-300' : 'text-primary-300'
                )}
              />
            )}

            {(!Array.isArray(value) || value.length < 3) && (
              <>
                <TbPhoto
                  size={24}
                  className={c(error ? 'text-danger-300' : 'text-primary-300')}
                />

                {!isDragActive && (
                  <p className="text-body-md font-medium text-light-gray-500">
                    {t('DRAGINDROP-TITLE')}&nbsp;{' '}
                    <span
                      className={c(
                        error ? 'text-danger-300' : 'text-primary-300'
                      )}
                    >
                      {t('DRAGINDROP-SECOND-TEXT')}
                    </span>
                  </p>
                )}

                {isDragActive && isDragAccept && (
                  <p className="text-body-md font-medium text-success-300">
                    {t('DRAGINDROP-VALID-FILE')}
                  </p>
                )}

                {isDragActive && isDragReject && (
                  <p className="text-body-md font-medium text-danger-300">
                    {t('DRAGINDROP-INVALID-FILE')}
                  </p>
                )}

                <p className="mb-4 text-body-md font-medium text-light-gray-400">
                  {t('DRAGINDROP-JUST-FILE')}{' '}
                  {mapAcceptToReadableString(['application/pdf'])}.{' '}
                  {t('DRAGINDROP-MAX-SIZE-FILE')} {prettyBytes(maxSizePerFile)}
                </p>
              </>
            )}
          </div>
        )}
      </div>

      <NotaFiscalModal
        file={file}
        label={label}
        onClose={clearFile}
        open={Boolean(file)}
        onSubmit={onSubmit.mutateAsync}
        tipoFixo={tipoFixo}
        propostaId={propostaId}
        milestoneId={milestoneId}
      />
    </div>
  )
}

export default NotaFiscalInput
