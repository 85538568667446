import * as React from 'react'
import { useField, useForm } from 'react-final-form'

import { useSkipFirstRender } from '@/hooks/core/useSkipFirstRender'
import { numonly } from '@/utils/core/etc'
import * as fetch from '@/utils/fetch'

import type { CnpjInputProps } from './cnpj-input'

export const useCnpjInput = ({
  name,
  accessors,
  shouldFetch,
}: Pick<CnpjInputProps, 'name' | 'accessors' | 'shouldFetch'>) => {
  const { batch, change } = useForm()
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } })
  const [fetchingCnpj, setFecthingCnpj] = React.useState(false)

  useSkipFirstRender(() => {
    if (!accessors || !shouldFetch) return

    const cnpjNumbersOnly = value?.replace(/[^\d]/g, '')
    if (!cnpjNumbersOnly || cnpjNumbersOnly.length !== 14 || fetchingCnpj)
      return

    setFecthingCnpj(true)

    fetch
      .portal(`/util/consulta_cnpj?cnpj=${cnpjNumbersOnly}`)
      .then((result: any) => {
        const estabelecimento = result.data.estabelecimento

        batch(() => {
          if (result.data.razao_social && accessors.razao_social) {
            change(accessors.razao_social, result.data.razao_social)
          }
          if (estabelecimento.nome_fantasia && accessors.nome_fantasia) {
            change(accessors.nome_fantasia, estabelecimento.nome_fantasia)
          }
          if (estabelecimento.cep && accessors.endereco_cep) {
            const split = estabelecimento.cep.split('')
            change(
              accessors.endereco_cep,
              `${split.slice(0, 5).join('')}-${split.slice(5).join('')}`
            )
          }
          if (estabelecimento.numero && accessors.endereco_numero) {
            change(accessors.endereco_numero, numonly(estabelecimento.numero))
          }
          if (estabelecimento.email && accessors.email) {
            change(accessors.email, estabelecimento.email.toLowerCase())
          }
          if (
            estabelecimento.ddd1 &&
            estabelecimento.telefone1 &&
            accessors.telefone
          ) {
            const split = estabelecimento.telefone1.split('')
            change(
              accessors.telefone,
              `(${estabelecimento.ddd1}) ${split.slice(0, 4).join('')}-${split
                .slice(4)
                .join('')}`
            )
          }
        })
      })
      .catch(() => undefined)
      .finally(() => setFecthingCnpj(false))
  }, [value, shouldFetch])

  return {}
}
