import * as React from 'react'

import type { MustHaveProps } from '@/hocs/with-form'

import FieldInput from '../field-input'
import { useCepInput } from './cep-input.hook'

export interface CepInputProps extends MustHaveProps {
  label?: string
  accessors?: {
    rua: string
    bairro: string
    cidade: string
    estado: string
  }
  disabled?: boolean
}

const CepInput: React.FCC<CepInputProps> = ({
  name,
  accessors,
  value,
  disabled,
  label = 'CEP',
  ...props
}) => {
  useCepInput({ name, accessors, disabled })
  return (
    <FieldInput
      name={name}
      label={label}
      type="cep"
      value={value as never}
      disabled={disabled}
      {...props}
    />
  )
}

export default CepInput
