import { Transition } from '@headlessui/react'
import * as Dialog from '@radix-ui/react-dialog'
import React from 'react'

import { c } from '@/utils/etc'

export interface BaseModalProps {
  open: boolean
  blur?: boolean
  isNewFicha?: boolean
  panelClassName?: string
  customWidth?: string
  onClose?: () => void
  renderActions?: () => React.ReactElement
}

const BaseModal: React.FCC<BaseModalProps> = ({
  open,
  blur,
  isNewFicha,
  panelClassName,
  children,
  onClose,
  renderActions,
  customWidth,
}) => {
  const openTime = React.useRef<number>()

  const close = React.useCallback(() => {
    const now = Date.now()
    if (now - (openTime.current || now) < 1000) return
    onClose && onClose()
  }, [onClose])

  React.useEffect(() => {
    if (open) openTime.current = Date.now()
  }, [open])

  return (
    <Transition.Root show={open}>
      <Dialog.Dialog open onOpenChange={close}>
        <Dialog.Portal className="relative z-50">
          <Dialog.Overlay
            className={
              'fixed grid p-6 inset-0 bg-black/30 z-40 place-items-center overflow-y-auto' +
              (blur ? ' backdrop-blur-xl' : '')
            }
          >
            <Transition.Child
              as={React.Fragment}
              enter="transform-gpu transition ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="transform-gpu transition duration-300"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              {isNewFicha ? (
                <div
                  className={c(
                    'container z-[50]',
                    'rounded-lg bg-white p-10',
                    customWidth ? customWidth : 'max-w-[500px]',
                    panelClassName
                  )}
                >
                  {children}

                  {renderActions && (
                    <div className="mt-6 flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:justify-center sm:space-x-4">
                      {renderActions()}
                    </div>
                  )}
                </div>
              ) : (
                <Dialog.Content
                  className={c(
                    'container z-50',
                    'rounded-lg bg-white p-10',
                    customWidth ? customWidth : 'max-w-[500px]',
                    panelClassName
                  )}
                >
                  {children}

                  {renderActions && (
                    <div className="mt-6 flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:justify-center sm:space-x-4">
                      {renderActions()}
                    </div>
                  )}
                </Dialog.Content>
              )}
            </Transition.Child>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Dialog>
    </Transition.Root>
  )
}

export default BaseModal
