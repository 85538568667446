import React from 'react'

interface ReadonlyProviderProps {
  defaultValue?: boolean
  children?:
    | React.ReactNode
    | ((p: { readonly: boolean }) => React.ReactElement)
}

export interface ReadonlyContextValue {
  readonly: boolean
  setEditable: () => void
  setReadonly: () => void
}

const ReadonlyContext = React.createContext<ReadonlyContextValue>({} as never)

export const useReadonly = () => React.useContext(ReadonlyContext)

export const ReadonlyProvider: React.FCC<ReadonlyProviderProps> = ({
  children,
  defaultValue = true,
}) => {
  const [value, setValue] = React.useState(defaultValue)

  const setEditable = React.useCallback(() => setValue(false), [])
  const setReadonly = React.useCallback(() => setValue(true), [])

  const v = React.useMemo(
    () => ({ readonly: value, setEditable, setReadonly }),
    [setEditable, setReadonly, value]
  )

  return (
    <ReadonlyContext.Provider value={v}>
      {typeof children === 'function' && children(v)}
      {typeof children !== 'function' && children}
    </ReadonlyContext.Provider>
  )
}
