import { Switch } from '@headlessui/react'
import React from 'react'

import { c } from '@/utils/etc'

import Wrap from '../Wrap/Wrap'
import type { SharedFieldInputProps } from './field-input/field-input'

export interface SwitchInputProps extends SharedFieldInputProps {
  checked?: boolean
  label?: string
  error?: boolean
  disabled?: boolean
  onChange?: (v: any) => void
}

const SwitchInput: React.FC<SwitchInputProps> = ({
  label,
  error: _error,
  disabled,
  containerClassName,
  labelClassName,
  checked,
  onChange,
}) => {
  const id = React.useId()

  const wrapper = React.useMemo<React.FC<{ children?: React.ReactNode }>>(
    () =>
      ({ children }) =>
        (
          <div
            className={c(
              'flex items-center gap-x-2 max-w-max',
              containerClassName
            )}
          >
            {children}
            <label
              htmlFor={id}
              className={c(
                'cursor-pointer font-medium text-dark-gray-500 select-none',
                labelClassName
              )}
            >
              {label}
            </label>
          </div>
        ),
    [label, containerClassName, labelClassName, id]
  )

  return (
    <Wrap wrap={Boolean(label)} wrapper={wrapper}>
      <Switch
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className={c(
          'relative inline-flex h-8 w-14 items-center rounded-full',
          checked ? 'bg-primary-300' : 'bg-light-gray-300'
        )}
      >
        {label && <span className="sr-only">{label}</span>}
        <span
          className={c(
            'inline-block h-6 w-6 transform rounded-full bg-white transition-transform',
            checked ? 'translate-x-7' : 'translate-x-1'
          )}
        />
      </Switch>
    </Wrap>
  )
}

export default SwitchInput
