import React from 'react'
import type { NumberFormatProps } from 'react-number-format'
import NumberFormat from 'react-number-format'

import type { MustHaveProps } from '@/hocs/with-form'
import { c, strtonum } from '@/utils/etc'

import { useInputMoney } from './MoneyInput.hook'

export interface SharedFieldInputProps {
  className?: string
  containerClassName?: string
  inputWrapperClassName?: string
  passthrough?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
}

export type MoneyInputProps = MustHaveProps &
  NumberFormatProps & {
    hasPrefix?: boolean
    name: string
    disabled?: boolean
    value?: string
    label?: string
    footer?: string
    error?: boolean
    placeholder?: string
    onChange?: (v: any) => void
    onBlur?: () => void
    onFocus?: () => void
    renderAfterLabel?: React.ReactNode
    children?: React.ReactNode
    maskOptions?: any
  }

const MoneyInput = ({
  value,
  onValueChange,
  name,
  label,
  footer,
  children,
  className,
  error,
  containerClassName,
  inputWrapperClassName,
  passthrough,
  disabled,
  placeholder,
  hasPrefix = true,
  onChange,
  onBlur,
  onFocus,
  renderAfterLabel,
}: MoneyInputProps) => {
  const {
    currencyFormatter,
    handleChange,
    id,
    before,
    after,
    prefix,
    locale,
    decimalScale,
    decimalSeparator,
    thousandSeparator,
  } = useInputMoney({
    onValueChange,
    children,
    name,
    onChange,
    hasPrefix,
    value,
  })

  return (
    <div
      className={c('group', error && 'form-field-error', containerClassName)}
    >
      {label && (
        <label htmlFor={id} className="form-label flex items-center">
          {label}
          {renderAfterLabel}
        </label>
      )}
      <div className={c(inputWrapperClassName)}>
        {before}
        <NumberFormat
          value={strtonum(value, locale) * 10 ** decimalScale}
          format={currencyFormatter}
          onValueChange={handleChange}
          prefix={prefix}
          allowEmptyFormatting
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          name={name}
          className={c('form-field', className)}
          onBlur={onBlur as never}
          onFocus={onFocus as never}
          {...(passthrough as any)}
        />
        {after}
      </div>

      {!error && footer ? (
        <p className="text-caption-md mt-2">{footer}</p>
      ) : null}
    </div>
  )
}

export default MoneyInput
